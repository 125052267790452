import React from "react";
import NewArrivalCollection from "../../components/NewArrivalCollection/NewArrivalCollection";

function NewArrivalCollectionPage() {
  return (
    <div>
      <NewArrivalCollection />
    </div>
  );
}

export default NewArrivalCollectionPage;
