import React from "react";
import TrendingCollection from "../../components/TrendingCollection/TrendingCollection";

function TrendingCollectionPage() {
  return (
    <div>
      <TrendingCollection />
    </div>
  );
}

export default TrendingCollectionPage;
