import React from "react";
import Auth from "../../components/Register/Auth";

function RegisterPage() {
  return (
    <div>
      <Auth />
    </div>
  );
}

export default RegisterPage;
