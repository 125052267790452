import React, { useState } from "react";
import { usePrismicDocumentsByType, PrismicRichText } from "@prismicio/react";
import { Link } from "react-router-dom";

function Feeds() {
  const [articles] = usePrismicDocumentsByType("article");
  return (
    <>
      <div className=" bg-[#E9ECEF]">
        <section class="py-20 ">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 class="font-manrope text-4xl font-bold text-gray-900 text-center mb-16">
              Our latest blog
            </h2>
            <div className="grid gap-8 grid-cols-1 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
              {articles?.results?.map((article, index) => (
                <Link to={`/article/${article.uid}`}>
                  <div class="group p-4 bg-white rounded-2xl">
                    <div class="flex items-center">
                      <img
                        src={article?.data.banner.url}
                        alt="blogs tailwind section"
                        class="rounded-t-2xl w-full h-52 object-cover"
                      />
                    </div>
                    <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                      <span class="text-red-900 font-semibold mb-3 block">
                        Jan 01, 2023
                      </span>
                      <h4 class="text-xl text-gray-900 font-bold line-clamp-1 leading-8 mb-3">
                        {" "}
                        <PrismicRichText field={article.data.heading} />
                      </h4>
                      <p class="text-gray-500 leading-6 font-semibold mb-6 line-clamp-3">
                        {" "}
                        <PrismicRichText field={article.data.description} />
                      </p>
                      <a
                        href="!#"
                        class="cursor-pointer text-lg text-red-900 font-semibold"
                      >
                        Read more..
                      </a>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </div>{" "}
      <div className="py-10"></div>
    </>
  );
}

export default Feeds;
