import React, { useState } from "react";
import { usePrismicDocumentsByType, PrismicRichText } from "@prismicio/react";
import { Link } from "react-router-dom";

function Feeds() {
  const [articles] = usePrismicDocumentsByType("article");
  return (
    <div className="py-16 -mt-24">
      <section class="text-gray-400 py-10 body-font bg-[#E9ECEF]">
        <div class="py-10">
          <h2 class="mb-4 text-center text-2xl font-bold  text-gray-800 md:mb-6 lg:text-5xl">
            Shops Insights and Feeds
          </h2>

          <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg"></p>
        </div>

        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
            {articles?.results?.map((article, index) => (
              <Link to={`/article/${article.uid}`}>
                <div class="group p-4 bg-white rounded-2xl">
                  <div class="flex items-center">
                    <img
                      src={article?.data.banner.url}
                      alt="blogs tailwind section"
                      class="rounded-t-2xl w-full h-72 object-cover"
                    />
                  </div>
                  <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                    <span class="text-red-900 font-semibold mb-3 block">
                      Jan 01, 2023
                    </span>
                    <h4 class="text-xl text-gray-900 font-bold line-clamp-1 leading-8 mb-3">
                      {" "}
                      <PrismicRichText field={article.data.heading} />
                    </h4>
                    <p class="text-gray-500 leading-6 font-semibold mb-6 line-clamp-3">
                      {" "}
                      <PrismicRichText field={article.data.description} />
                    </p>
                    <a
                      href="!#"
                      class="cursor-pointer text-lg text-red-900 font-semibold"
                    >
                      Read more..
                    </a>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="justify-center text-center mt-4 py-10">
          <Link
            to="/blog"
            class="inline-block rounded-lg border justify-center text-center bg-gray-50 px-6 py-2  text-sm font-semibold text-gray-700 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
          >
            View More Articles
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Feeds;
