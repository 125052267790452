import React, { useEffect, useState } from "react";
import { Link, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
// import Navbar2 from "./components/Navbar2/Navbar2";
import Navbar1 from "./components/Navbar/DownNav";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import {
  HomePage,
  AboutPage,
  ContactPage,
  ProductPage,
  NewArrivalCollectionPage,
  ProductDetailPage,
  CheckoutPage,
  RegisterPage,
  TrendingCollectionPage,
  ProfilePage,
  BlogPostPage,
  OrderPage,
  Refund,
} from "./pages/index";
import SignIn from "./components/Register/SignIn";
import ReadBlog from "./pages/BlogPost/ReadBlog";
import Career from "./pages/Career/Career";
import Fav from "./pages/Fav/FavPage";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import Guide from "./pages/Guide/Guide";
import Socials from "./components/Socials/Socials";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const modalShownBefore = localStorage.getItem("modalShown");

    if (!modalShownBefore) {
      localStorage.setItem("modalShown", "true");
      setShowModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return <Loader />; // Show loader while loading
  }
  return (
    <>
      <ScrollToTop />
      <div>
        {showModal && (
          <div
            className="relative z-10 modal"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 "></div>

            <div className="fixed inset-0 py-24 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg  text-left  sm:my-8 sm:w-full sm:max-w-lg">
                  <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="">
                      <Link
                        to="/SignIn"
                        className="mx-auto  flex flex-shrink-0 items-center justify-center   sm:mx-0"
                      >
                        <img
                          src="https://dl.dropboxusercontent.com/scl/fi/6m70ab42t40i3lhcf5fdy/Pop-up.png?rlkey=c9pjyqno8u2d6m9vknxytjts0&dl=0"
                          className="max-w-lg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <button
                      onClick={closeModal}
                      className="modal-close-button py-2 text-lg text-white font-bold"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Navbar />
        <Navbar1 />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/product/:category" element={<ProductPage />} />
          <Route
            path="/product/:category/:subcategory"
            element={<ProductPage />}
          />
          <Route
            path="/product/:category/:subcategory/:subcategory1"
            element={<ProductPage />}
          />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/newArrival" element={<NewArrivalCollectionPage />} />
          <Route path="/trending" element={<TrendingCollectionPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route
            path="/productdetail/:productId"
            element={<ProductDetailPage />}
          />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/article/:slug" element={<ReadBlog />} />
          <Route path="/blog" element={<BlogPostPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term" element={<Terms />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/fav" element={<Fav />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/order" element={<OrderPage />} />
        </Routes>
        <Socials />
        <Footer />
      </div>
    </>
  );
};

export default App;
