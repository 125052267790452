import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
function SignUp() {
  const [user, setUser] = React.useState({
    username: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    if (!validateEmail(user.email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError(""); // Reset error if email is valid
    }
    console.log(user);

    axios
      .post("/user/reg", user)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "You have successfully signed up!",
            icon: "success",
            confirmButtonText: "ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/SignIn");
            }
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Please try again!",
            icon: "error",
            confirmButtonText: "Cool",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "Please try again!",
          icon: "error",
          confirmButtonText: "ok",
        });
      });
  };

  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <div class="h-full">
        <div class=" flex h-full bg-[#E9ECEF] items-center py-6">
          <main class="w-full max-w-md mx-auto p-6">
            <div class="mt-7 bg-white  rounded-xl shadow-lg  ">
              <div class="p-4 sm:p-7">
                <div class="text-center">
                  <h1 class="block text-2xl font-bold text-gray-800 ">
                    Sign up
                  </h1>
                </div>

                <div class="mt-5">
                  <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 ">
                    Or
                  </div>

                  <form>
                    <div class="grid gap-y-4">
                      <div>
                        <label
                          for="email"
                          class="block text-sm font-bold mb-2 "
                        >
                          Email address
                        </label>
                        <div class="relative">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={user.email}
                            onChange={(e) =>
                              setUser({ ...user, email: e.target.value })
                            }
                            class="py-3 px-4 block w-full border border-gray-300 rounded-md text-sm font-bold focus:border-blue-500 focus:ring-blue-500 "
                            required
                            aria-describedby="email-error"
                          />
                          {emailError && (
                            <p
                              className="text-xs text-red-600 font-bold mt-2"
                              id="email-error"
                            >
                              {emailError}
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <label
                          for="password"
                          class="block text-sm font-bold mb-2 "
                        >
                          Username
                        </label>
                        <div class="relative">
                          <input
                            type="text"
                            value={user.username}
                            onChange={(e) =>
                              setUser({ ...user, username: e.target.value })
                            }
                            name="username"
                            id="username"
                            placeholder="username"
                            class="py-3 px-4 block w-full border border-gray-300 rounded-md text-sm font-bold focus:border-blue-500 focus:ring-blue-500 "
                            required
                            aria-describedby="password-error"
                          />
                          <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg
                              class="h-5 w-5 text-red-500"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                              aria-hidden="true"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          for="confirm-password"
                          class="block text-sm font-bold mb-2"
                        >
                          Password
                        </label>
                        <div class="relative">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={user.password}
                            onChange={(e) =>
                              setUser({ ...user, password: e.target.value })
                            }
                            class="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm font-bold focus:border-blue-500 focus:ring-blue-500 "
                            required
                            aria-describedby="confirm-password-error"
                          />
                          <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg
                              class="h-5 w-5 text-red-500"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                              aria-hidden="true"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                          </div>
                        </div>
                        <p
                          class="font-bold text-xs text-gray-900 mt-3"
                          id="confirm-password-note"
                        >
                          Use 1 special character, 1 small alphabets , Number
                        </p>
                      </div>

                      <div class="flex items-center">
                        <div class="flex">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600  focus:ring-blue-500 "
                          />
                        </div>
                        <div class="ml-3">
                          <label for="remember-me" class="text-sm font-bold ">
                            I accept the{" "}
                            <a
                              class="text-blue-600 decoration-2 hover:underline underline-offset-8 font-bold"
                              href="!#"
                            >
                              Terms and Conditions
                            </a>
                          </label>
                        </div>
                      </div>

                      <button
                        onClick={handleSignUp}
                        type="submit"
                        class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm font-bold dark:focus:ring-offset-gray-800"
                      >
                        Sign up
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
