import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import company from "./company_profile.pdf";

// import MenuItems from "./MenuItems";
import axios from "../../api/axios";

function DownNav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dropCat, setDropCat] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [carts, setCarts] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeDropdown = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  var username = localStorage.getItem("username");
  const navigate = useNavigate();
  const handleLogout = async () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [dropdownOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [dropdownOpen]);

  useEffect(() => {
    axios
      .get(`/category/category`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAddtoCart = async (cartProduct) => {
    const cart = {
      productName: cartProduct.productName,
      price: cartProduct.price,
      imageUrl: cartProduct.imageUrl,
      quantity: 1,
    };
    var id = localStorage.getItem("id");
    await axios
      .post(`/cart/cart/${id}`, cart)
      .then((res) => {
        setCarts(res.data);
        setCartCount((prevCount) => {
          const newCount = prevCount + 1;

          return newCount;
        });

        alert("Added");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderSubcategories1 = (categoryName, subCatName, subcategories1) => {
    return subcategories1.map((subcategory1, index) => (
      <Link to={`/product/${categoryName}/${subCatName}/${subcategory1.name}`}>
        <li
          key={`subcategory1-${index}`}
          className="px-3 py-1 hover:bg-gray-100"
        >
          {subcategory1.name}
        </li>
      </Link>
    ));
  };

  const renderSubcategories = (categoryName, subcategories) => {
    return subcategories.map((subcategory, index) => (
      <li
        key={`subcategory-${index}`}
        className="flex px-3 py-1 hover:bg-gray-100"
      >
        <Link
          to={
            subcategory.subcategories1.length === 0 &&
            `/product/${categoryName}/${subcategory.name}`
          }
          className="flex"
        >
          {subcategory.name}
          {subcategory.subcategories1.length > 0 && (
            <span className="mr-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="ml-2 fill-current mt-2 h-4 w-4 transition duration-150 ease-in-out"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
          )}
        </Link>
        {subcategory.subcategories1.length > 0 &&
          subcategory.subcategories1 && (
            <ul className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left w-full">
              {renderSubcategories1(
                categoryName,
                subcategory.name,
                subcategory.subcategories1
              )}
            </ul>
          )}
      </li>
    ));
  };

  const renderCategories = (categories) => {
    return categories.map((category, index) => (
      <>
        <li
          key={`category-${index}`}
          className="rounded-sm relative px-3 py-1 hover:bg-white  font-[20px]"
          onClick={() => setDropCat(category.category.name)}
        >
          <Link
            onClick={() => {
              if (category.category.subcategory.length === 0) {
                setIsMenuOpen(false);
              }
            }}
            to={
              category.category.subcategory.length === 0 &&
              `/product/${category.category.name}`
            }
          >
            <button className="w-full text-left  text-black text-base flex items-center outline-none focus:outline-none">
              <span className="pr-1 flex">{category.category.name}</span>
              {category.category.subcategory.length > 0 && (
                <span className="mr-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </span>
              )}
            </button>
          </Link>
          {category.category.subcategory.length > 0 && (
            <ul className="bg-white hidden md:block border rounded-sm text-black absolute  top-0 right-0 transition duration-150 ease-in-out origin-top-left w-full">
              {renderSubcategories(
                category.category.name,
                category.category.subcategory
              )}
            </ul>
          )}
          {category.category.subcategory.length > 0 &&
            category.category.subcategory.map((subcategory) => (
              <>
                {" "}
                {category.category.name === dropCat && (
                  <li key={subcategory.name} className="md:hidden  block">
                    {" "}
                    <Link
                      onClick={() => {
                        if (subcategory.subcategories1.length === 0) {
                          setIsMenuOpen(false);
                        }
                      }}
                      to={
                        subcategory.subcategories1.length === 0 &&
                        `/product/${category.category.name}/${subcategory.name}`
                      }
                    >
                      <button className="w-full text-left  px-4 flex  items-center outline-none focus:outline-none">
                        <span className="text-red-900 text-lg  mt-1  font-normal sm:text-base">
                          {" "}
                          {subcategory.name}
                        </span>
                        {subcategory.subcategories1.length > 0 && (
                          <span className="mr-auto">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className=" fill-current h-4 w-4 transition duration-150 ease-in-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </Link>
                    {subcategory.subcategories1.length > 0 && (
                      <ul
                        onClick={() => setIsMenuOpen(false)}
                        className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left w-full"
                      >
                        {renderSubcategories1(
                          category.category.name,
                          subcategory.name,
                          subcategory.subcategories1
                        )}
                      </ul>
                    )}
                  </li>
                )}
              </>
            ))}
        </li>
        <hr className="my-2 md:my-0 block md:hidden" />
      </>
    ));
  };

  return (
    <div className="sticky-container bg-black shadow">
      <div className="px-4 py-5  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-full md:px-24 lg:px-11">
        <div className="relative flex items-center justify-between">
          <Link to="/">
            <img
              src="/logo (2) 1.png"
              alt=""
              className="ml-1 h-10 w-16 sm:h-auto sm:w-20"
            />
          </Link>
          <ul className="flex items-center hidden  text-base font-bold  text-white  space-x-6 lg:flex">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About us</Link>
            </li>

            <li>
              <div className="group inline-block">
                <button className="outline-none focus:outline-none px-3  py-1  rounded-sm flex items-center ">
                  <span className="pr-1 flex-1">Product</span>
                  <span>
                    <svg
                      className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className="bg-white border py-4  rounded-sm transform scale-0  mt-8 group-hover:scale-100 absolute transition duration-700  origin-top">
                  {renderCategories(categories)}
                </ul>
              </div>
            </li>
            <li>
              <a href={company} target="_blank" rel="noopener noreferrer">
                Company Profile
              </a>
            </li>

            <li>
              <Link to="/blog">Blogs</Link>{" "}
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <div className=" flex font-bold items-center space-x-4 text-white ">
              <Link to="/checkout" href="#!">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-cart"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />{" "}
                </svg>
                {cartCount > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-black rounded-full px-1">
                    {cartCount}
                  </span>
                )}
              </Link>

              <Link to="/fav" className="flex items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-heart"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />{" "}
                </svg>
              </Link>
              <Link to="/register">
                <div className="relative -mt-2">
                  <Link
                    ref={trigger}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="flex items-center gap-4"
                    to="#"
                  >
                    <span className="hidden text-right lg:block">
                      <span className="text-lg font-bold tracking-wide text-white">
                        {username}
                      </span>
                    </span>

                    <span className="h-8 w-8 rounded-full bg-white">
                      <img src="/icons8-male-user-50.png " alt="User" />
                    </span>

                    <svg
                      className={`hidden fill-current sm:block ${
                        dropdownOpen ? "rotate-180" : ""
                      }`}
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                        fill=""
                      />
                    </svg>
                  </Link>

                  <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                    className={`absolute right-0 mt-9 flex  flex-col rounded-sm border  bg-white shadow-default  ${
                      dropdownOpen === true ? "block" : "hidden"
                    }`}
                  >
                    <ul className="flex flex-col text-black gap-5 border-b border-stroke px-6 py-4 w-48 text-center dark:border-strokedark">
                      <li>
                        <Link
                          to="/order"
                          className="flex items-center gap-3.5 text-sm  duration-300 ease-in-out font-bold hover:text-primary lg:text-base"
                        >
                          <svg
                            className="fill-current"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.6687 1.44374C17.1187 0.893744 16.4312 0.618744 15.675 0.618744H7.42498C6.25623 0.618744 5.25935 1.58124 5.25935 2.78437V4.12499H4.29685C3.88435 4.12499 3.50623 4.46874 3.50623 4.91562C3.50623 5.36249 3.84998 5.70624 4.29685 5.70624H5.25935V10.2781H4.29685C3.88435 10.2781 3.50623 10.6219 3.50623 11.0687C3.50623 11.4812 3.84998 11.8594 4.29685 11.8594H5.25935V16.4312H4.29685C3.88435 16.4312 3.50623 16.775 3.50623 17.2219C3.50623 17.6687 3.84998 18.0125 4.29685 18.0125H5.25935V19.25C5.25935 20.4187 6.22185 21.4156 7.42498 21.4156H15.675C17.2218 21.4156 18.4937 20.1437 18.5281 18.5969V3.47187C18.4937 2.68124 18.2187 1.95937 17.6687 1.44374ZM16.9469 18.5625C16.9469 19.2844 16.3625 19.8344 15.6406 19.8344H7.3906C7.04685 19.8344 6.77185 19.5594 6.77185 19.2156V17.875H8.6281C9.0406 17.875 9.41873 17.5312 9.41873 17.0844C9.41873 16.6375 9.07498 16.2937 8.6281 16.2937H6.77185V11.7906H8.6281C9.0406 11.7906 9.41873 11.4469 9.41873 11C9.41873 10.5875 9.07498 10.2094 8.6281 10.2094H6.77185V5.63749H8.6281C9.0406 5.63749 9.41873 5.29374 9.41873 4.84687C9.41873 4.39999 9.07498 4.05624 8.6281 4.05624H6.77185V2.74999C6.77185 2.40624 7.04685 2.13124 7.3906 2.13124H15.6406C15.9844 2.13124 16.2937 2.26874 16.5687 2.50937C16.8094 2.74999 16.9469 3.09374 16.9469 3.43749V18.5625Z"
                              fill=""
                            />
                          </svg>
                          My Orders
                        </Link>
                      </li>
                    </ul>

                    {username ? (
                      <Link to="/register" aria-label="Sign up" title="Sign up">
                        <button
                          className="flex items-center gap-3.5 py-4 px-6 text-black text-sm font-bold duration-300 ease-in-out hover:text-primary lg:text-base"
                          onClick={handleLogout}
                        >
                          <svg
                            className="fill-current"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.5375 0.618744H11.6531C10.7594 0.618744 10.0031 1.37499 10.0031 2.26874V4.64062C10.0031 5.05312 10.3469 5.39687 10.7594 5.39687C11.1719 5.39687 11.55 5.05312 11.55 4.64062V2.23437C11.55 2.16562 11.5844 2.13124 11.6531 2.13124H15.5375C16.3625 2.13124 17.0156 2.78437 17.0156 3.60937V18.3562C17.0156 19.1812 16.3625 19.8344 15.5375 19.8344H11.6531C11.5844 19.8344 11.55 19.8 11.55 19.7312V17.3594C11.55 16.9469 11.2062 16.6031 10.7594 16.6031C10.3125 16.6031 10.0031 16.9469 10.0031 17.3594V19.7312C10.0031 20.625 10.7594 21.3812 11.6531 21.3812H15.5375C17.2219 21.3812 18.5625 20.0062 18.5625 18.3562V3.64374C18.5625 1.95937 17.1875 0.618744 15.5375 0.618744Z"
                              fill=""
                            />
                            <path
                              d="M6.05001 11.7563H12.2031C12.6156 11.7563 12.9594 11.4125 12.9594 11C12.9594 10.5875 12.6156 10.2438 12.2031 10.2438H6.08439L8.21564 8.07813C8.52501 7.76875 8.52501 7.2875 8.21564 6.97812C7.90626 6.66875 7.42501 6.66875 7.11564 6.97812L3.67814 10.4844C3.36876 10.7938 3.36876 11.275 3.67814 11.5844L7.11564 15.0906C7.25314 15.2281 7.45939 15.3312 7.66564 15.3312C7.87189 15.3312 8.04376 15.2625 8.21564 15.125C8.52501 14.8156 8.52501 14.3344 8.21564 14.025L6.05001 11.7563Z"
                              fill=""
                            />
                          </svg>
                          Log Out
                        </button>
                      </Link>
                    ) : (
                      <Link to="/register" aria-label="Sign up" title="Sign up">
                        <button className="flex items-center gap-3.5 py-4 px-6 text-sm text-black font-bold duration-300 ease-in-out hover:text-primary lg:text-base">
                          <svg
                            className="fill-current"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.5375 0.618744H11.6531C10.7594 0.618744 10.0031 1.37499 10.0031 2.26874V4.64062C10.0031 5.05312 10.3469 5.39687 10.7594 5.39687C11.1719 5.39687 11.55 5.05312 11.55 4.64062V2.23437C11.55 2.16562 11.5844 2.13124 11.6531 2.13124H15.5375C16.3625 2.13124 17.0156 2.78437 17.0156 3.60937V18.3562C17.0156 19.1812 16.3625 19.8344 15.5375 19.8344H11.6531C11.5844 19.8344 11.55 19.8 11.55 19.7312V17.3594C11.55 16.9469 11.2062 16.6031 10.7594 16.6031C10.3125 16.6031 10.0031 16.9469 10.0031 17.3594V19.7312C10.0031 20.625 10.7594 21.3812 11.6531 21.3812H15.5375C17.2219 21.3812 18.5625 20.0062 18.5625 18.3562V3.64374C18.5625 1.95937 17.1875 0.618744 15.5375 0.618744Z"
                              fill=""
                            />
                            <path
                              d="M6.05001 11.7563H12.2031C12.6156 11.7563 12.9594 11.4125 12.9594 11C12.9594 10.5875 12.6156 10.2438 12.2031 10.2438H6.08439L8.21564 8.07813C8.52501 7.76875 8.52501 7.2875 8.21564 6.97812C7.90626 6.66875 7.42501 6.66875 7.11564 6.97812L3.67814 10.4844C3.36876 10.7938 3.36876 11.275 3.67814 11.5844L7.11564 15.0906C7.25314 15.2281 7.45939 15.3312 7.66564 15.3312C7.87189 15.3312 8.04376 15.2625 8.21564 15.125C8.52501 14.8156 8.52501 14.3344 8.21564 14.025L6.05001 11.7563Z"
                              fill=""
                            />
                          </svg>
                          SignIn
                        </button>
                      </Link>
                    )}
                  </div>
                  {/* <!-- Dropdown End --> */}
                </div>
              </Link>
            </div>
          </ul>

          <div className="lg:hidden">
            <div className="flex justify-center">
              <ul className="flex mt-2 items-center  space-x-8 lg:flex">
                <div className=" flex font-bold items-center space-x-4 text-white ">
                  <Link to="/checkout" href="#!">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-cart"
                      viewBox="0 0 24 24"
                    >
                      {" "}
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />{" "}
                    </svg>
                    {cartCount > 0 && (
                      <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-1">
                        {cartCount}
                      </span>
                    )}
                  </Link>

                  <Link to="/fav" className="flex items-center ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-heart"
                      viewBox="0 0 24 24"
                    >
                      {" "}
                      <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />{" "}
                    </svg>
                  </Link>
                </div>
              </ul>
              <button
                aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
                title={isMenuOpen ? "Close Menu" : "Open Menu"}
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? (
                  <svg className="w-5 text-gray-200" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 
           c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 
           c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                    />
                  </svg>
                ) : (
                  <svg className="w-5 text-gray-200" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                    />
                  </svg>
                )}
              </button>
            </div>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <div className="">
                  <nav className="fixed top-32 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-3 px-6 bg-white border-r overflow-y-auto">
                    <div className="">
                      <ul>
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Home
                          </Link>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/about"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            About Us
                          </Link>
                        </li>
                        <hr />
                        <li className="mb-1 relative">
                          <div className="group inline-block">
                            <button className="flex items-center  py-3 text-lg font-bold text-black hover:text-red-900 rounded">
                              <span className="">Product</span>
                              <span className="px-4">
                                <svg
                                  className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </span>
                            </button>
                            <ul className="z-20 px-4 py-3 text-lg font-bold text-black hover:text-red-900 rounded bg-white border mt-2 absolute top-full left-0 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top min-w-32 shadow-lg">
                              {renderCategories(categories)}
                            </ul>
                          </div>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <a
                            href={company}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                          >
                            Company Profile
                          </a>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/blog"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Blogs
                          </Link>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/order"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            My Orders
                          </Link>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/contact"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Contact
                          </Link>
                        </li>
                        <hr />
                        <li className="mb-1">
                          <Link
                            className="block  py-3 text-lg font-bold text-black  hover:text-red-900 rounded"
                            to="/register"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Signup / Login
                          </Link>
                        </li>

                        <hr />
                      </ul>
                    </div>
                    <div className="mt-auto">
                      <div className="pt-4">
                        <div className="relative">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.7099745840196!2d88.36971537591751!3d22.55253463370427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277c15d823f27%3A0xeaf23fc7d8055200!2sAriz%20Garments!5e0!3m2!1sen!2sin!4v1697614258929!5m2!1sen!2sin"
                            style={{ border: "0" }}
                            className=" w-full h-80"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                        <br />
                      </div>
                      <p className="my-4 text-sm text-start text-black font-bold">
                        <span>
                          Ariz Garments (A Unit of El Ombre Exports Pvt Ltd.)
                        </span>
                      </p>
                    </div>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownNav;
