import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../Filter/Filter";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NewArrivalCollection() {
  const navigate = useNavigate();
  const [newArrivals, setNewArrivals] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [showCount, setShowCount] = useState(9);
  const maxShowCount = 9;
  const [carts, setCarts] = useState([]);
  const [isFilterFixed, setIsFilterFixed] = useState(false);
  const [variantSelected, setVariantSelected] = useState(0);
  const [selectedNewArrivals, setSelectedNewArrivals] = useState([]);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsFilterFixed(true);
    } else {
      setIsFilterFixed(false);
    }
  };

  useEffect(() => {
    axios
      .get(`/userProduct/userProduct?limit=${showCount}`)
      .then((res) => {
        const newArrivalProducts = res.data.filter(
          (product) => product.type === "New Arrival"
        );

        setNewArrivals(newArrivalProducts.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showCount]);

  const handleAddtoCart = async (cartProduct) => {
    const selectedVariant = cartProduct.variant[variantSelected];

    const cart = {
      productName: selectedVariant.productName,
      price: selectedVariant.price,
      imageUrl: selectedVariant.image[0],
      quantity: 1,
    };
    var id = localStorage.getItem("id");
    if (!id) {
      toast("Please log in to add items to the cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
      return;
    }
    await axios
      .post(`/cart/cart/${id}`, cart)
      .then((res) => {
        setCarts(res.data);
        toast("Added To Cart !", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          rtl: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          pauseOnFocusLoss: true,
          progress: undefined,
          transition: Slide,
          theme: "dark",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [message, setMessage] = React.useState();

  const chooseMessage = (message) => {
    setMessage(message);
    if (message === 1) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "XS") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 2) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "S") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 3) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "M") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 4) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "L") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 5) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "XL") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
  };

  return (
    <div>
      <div class=" py-6 sm:py-8 lg:py-12 bg-[#E9ECEF]">
        <h2 class="text-4xl text-center font-bold text-gray-800 lg:text-5xl mb-8">
          New Arrivals
        </h2>
        <div class="mx-auto max-w-screen-2xl sm:flex ">
          <div
            className={`sticky -mt-6 top-0 md:top-0 z-20 ${
              isFilterFixed ? "h-full" : ""
            }`}
          >
            <Filter chooseMessage={chooseMessage} />
          </div>
          <div className="py-6 sm:py-8 lg:py-0">
            <div className="mx-auto max-w-screen-xl px-4 md:px-6">
              <div className="flex flex-wrap w-full">
                {newArrivals.slice(0, showCount).map((newArrival, index) =>
                  newArrival.variant.map((variant, vIndex) => (
                    <div
                      key={index}
                      className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 mb-4 p-2"
                    >
                      <div key={vIndex} className="mb-4">
                        <button
                          onClick={() =>
                            navigate(`/productdetail/${newArrival._id}`, {
                              state: [newArrival],
                            })
                          }
                          className={`group relative block  w-full aspect-w-4 aspect-h-5 overflow-hidden rounded bg-gray-100 shadow-sm ${
                            selectedNewArrivals.includes(newArrival._id)
                              ? "border-4 border-primary"
                              : ""
                          }`}
                        >
                          <img
                            src={variant.image[0]}
                            loading="lazy"
                            alt=""
                            className="h-full w-full object-center transition duration-200 group-hover:scale-110"
                          />
                          {newArrival.variant[variantSelected].price !==
                            newArrival.variant[variantSelected].mrp && (
                            <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">
                              Sale
                            </span>
                          )}
                        </button>

                        <div class="flex items-center justify-center gap-2 px-2">
                          <div class="mt-4 flex flex-col h-28">
                            <a
                              href="!#"
                              class="text-xs  text-gray-800 transition duration-100 hover:text-gray-500 lg:text-lg font-bold line-clamp-2"
                            >
                              {variant.productName}
                            </a>
                            <div class="flex">
                              <span class="text-gray-800 py-1 text-sm font-semibold">
                                Rs {newArrival.variant[variantSelected].price}
                              </span>
                              {newArrival.variant[variantSelected].price !==
                                newArrival.variant[variantSelected].mrp && (
                                <span class="text-gray-800 flex py-1 px-2 text-sm font-semibold">
                                  <p className="px-2 font-bold line-through">
                                    ₹ {newArrival.variant[variantSelected].mrp}
                                  </p>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="py-0">
                          <button
                            onClick={() => handleAddtoCart(newArrival)}
                            className="bg-gray-300 rounded text-black font-semibold  py-3 px-3 w-full "
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center  justify-center my-8 w-full">
          <Link to="/newArrival?isNewArrival=true">
            <button
              onClick={() => setShowCount(showCount + 9)}
              className=" shadow-md shadow-gray-300  tracking-wider font-assistant  lg:text-xl md:text-lg text-sm rounded f-m-m font-semibold text-white focus:outline-none lg:px-8 px-6 lg:py-6 py-3 xl:leading-4"
              style={{ background: "#AD5C5C" }}
            >
              Browse More
            </button>
          </Link>
        </div>
      </div>
      <div className="py-8"></div>
      <p className="text-xl text-black">{chooseMessage ?? "N/A"}</p>
    </div>
  );
}

export default NewArrivalCollection;
