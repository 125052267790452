import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "../../api/axios";

function HomeBanner2() {
  const [banner, setBanner] = useState([]);
  const [showCount, setShowCount] = useState(1);

  useEffect(() => {
    axios
      .get(`/banner2/banner2?limit=${showCount}`)
      .then((res) => {
        setBanner(res.data.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="py-0 justify-center">
        {banner?.slice(0, showCount)?.map((banner, index) => (
          <Link
            key={index}
            // to={`/product/${banner.category}`}
            to={`/product/Indian-Indo Western/Saree`}
          >
            <img src={banner?.imageUrl} alt="" className="" />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomeBanner2;
