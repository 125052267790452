import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";

function Category() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios
      .get(`/category/category`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const filteredCategories = categories.filter(
    (category) => category.category.name === "CO-ORD SET"
  );

  const dressCategories = categories.filter(
    (category) => category.category.name === "Dresses"
  );

  const onlyCategories = categories.filter(
    (category) => category.category.name === "Tops"
  );

  const Categories = categories.filter(
    (category) => category.category.name === "Bottoms"
  );

  return (
    <div className="">
      <div className="flex  justify-center items-center">
        <div className=" mx-auto container py-12 px-2 sm:px-6 xl:px-20 2xl:px-20 ">
          <div className="flex flex-col jusitfy-center items-center space-y-10">
            <div className="grid grid-cols-3 gap-x-3 md:grid-cols-1 lg:grid-cols-3 md:gap-x-6 w-full">
              {Categories.map((category, index) => (
                <Link
                  key={index}
                  to={`/product/Indian-Indo Western/Saree`}
                  className="relative group flex justify-center items-center h-full w-full"
                >
                  <img
                    className="object-center object-cover h-full w-full"
                    src="https://dl.dropboxusercontent.com/scl/fi/sd9e5tqccyz1mqfaa0rn7/Saree-Category-copy.jpg?rlkey=hneuccdgr9invut6kz0iqgfsa&st=vh62jf40&dl=0"
                    alt="Saree"
                  />

                  {/* <button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 px-4 md:py-3 md:w-36   bg-white">
                    {category.category.name}
                  </button> */}
                  {/* <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" /> */}
                </Link>
              ))}

              <div className="flex flex-col items-center sm:items-start space-y-3 md:space-y-8  md:mt-0">
                {filteredCategories.map((category, index) => (
                  <Link
                    key={index}
                    to={`/product/Tops/Shirts`}
                    className="relative group flex justify-center items-center
                  h-full w-full"
                  >
                    <img
                      className="object-center object-cover h-full w-full"
                      src="  https://dl.dropboxusercontent.com/scl/fi/byoxczu5dby5xzz85ne4b/Shirt-category-2-copy.jpg?rlkey=k2808syfun4hssa2ah4936a63&st=pk0e8f3q&dl=0"
                      alt=""
                    />
                    {/* <button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 px-4 md:py-3 md:w-36  bg-white">
                      {category.category.name}
                    </button> */}
                    {/* <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" /> */}
                  </Link>
                ))}
                {dressCategories.map((category, index) => (
                  <Link
                    key={index}
                    to={`/product/Dresses/Dresses`}
                    className="relative group flex justify-center items-center
                  h-full w-full"
                  >
                    <img
                      className="object-center object-cover h-full w-full"
                      src="   https://dl.dropboxusercontent.com/scl/fi/c1w7awnhhw0jew8jcsyg5/Dresses-category-2-copy.jpg?rlkey=2ei7f1d51kl9wewyicimypsgc&st=n7k573ij&dl=0"
                      alt=""
                    />
                    {/* <button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 px-4 md:py-3 md:w-36  bg-white">
                      {category.category.name}
                    </button> */}
                    {/* <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" /> */}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col space-y-4 items-center sm:items-start md:space-y-8  md:mt-0">
                {onlyCategories.map((category, index) => (
                  <Link
                    to={`/product/Dresses/Gowns`}
                    key={index}
                    className="relative group flex justify-center items-center h-full w-full"
                  >
                    <img
                      className="object-center object-cover h-full w-full"
                      src=" https://dl.dropboxusercontent.com/scl/fi/gzzi3umvvju61ayj5uhlv/Gown-Category-copy.jpg?rlkey=eg4vpzfsh528pezzunfiy452o&st=gjath5ou&dl=0"
                      alt=""
                    />
                    {/* <button className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 px-4 md:py-3 md:w-36  bg-white">
                      {category.category.name}
                    </button> */}
                    {/* <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" /> */}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
