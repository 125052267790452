import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";

function FavoriteProductsPage({ products }) {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [show1, setshow1] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    var total = 0;
    var id = localStorage.getItem("id");
    axios
      .get(`/fav/fav/${id}`)
      .then((res) => {
        console.log("API Response:", res.data);
        setFavoriteProducts(res.data.fav);
        for (let i = 0; i < res.data.fav.length; i++) {
          total += parseInt(res.data.fav[i].price);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (itemId) => {
    try {
      const id = localStorage.getItem("id");
      await axios.delete(`/fav/fav/${id}/${itemId}`);
      fetchData();

      Swal.fire({
        title: "Success!",
        text: "fav removed from the list",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error(error);
      alert("Error deleting item from the cart");
    }
  };

  return (
    <div className="bg-[#E9ECEF] mb-16">
      <div className="mx-auto container px-4 md:px-6 2xl:px-0 py-12 flex justify-center items-center">
        <div className="flex flex-col jusitfy-center items-center">
          <div className="mt-3">
            <h1 className="text-3xl lg:text-4xl tracking-tight font-semibold text-center leading-8 lg:leading-9 text-gray-800">
              Favourites
            </h1>
          </div>
          <div>
            <div className=" mt-10 lg:mt-12 grid grid-cols-1 lg:grid-cols-5 gap-x-4 gap-y-10 lg:gap-y-0">
              {favoriteProducts && favoriteProducts.length > 0 ? (
                favoriteProducts.map((favProduct, index) => (
                  <div key={index} className="flex flex-col ">
                    <div className="relative">
                      <div class="group relative mb-2 block overflow-hidden rounded bg-gray-100 shadow-sm  lg:mb-3">
                        <img
                          src={favProduct?.imageUrl}
                          loading="lazy"
                          alt=""
                          className="h-full md:h-96 w-full   object-center transition duration-200 group-hover:scale-110"
                        />
                      </div>
                    </div>
                    <div className="mt-3 flex justify-between items-center">
                      <div className="flex justify-center items-center">
                        <p className="tracking-wide   text-base font-bold leading-6 text-gray-800">
                          {favProduct.productName}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="tracking-tight text-base font-medium  py-3 leading-3 text-gray-800">
                        Color : {favProduct?.color}
                      </p>
                    </div>
                    <div className="">
                      <p className="tracking-tight text-base font-medium leading-4 text-gray-800">
                        Size : {favProduct.size}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="tracking-tight text-base font-medium leading-4 text-gray-800">
                        Price : {favProduct.price}
                      </p>
                    </div>

                    <div className="flex jusitfy-between flex-col lg:flex-row items-center mt-10 w-full  space-y-4 lg:space-y-0 lg:space-x-4 xl:space-x-8">
                      <div className="w-full">
                        <button
                          type="button"
                          onClick={() => handleDelete(favProduct._id)}
                          className="focus:outline-none focus:ring-gray-800 focus:ring-offset-2 focus:ring-2  text-black font-bold hover:text-white w-full tracking-tight py-4 text-lg leading-4  hover:bg-black bg-gray-300 "
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p class="mt-4 text-gray-800 font-bold text-center text-2xl">
                  Add Favourite products in wishlist to view{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FavoriteProductsPage;
