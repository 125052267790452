import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Filter from "../Filter/Filter";

function TrendingCollection() {
  const navigate = useNavigate();
  const [trendings, setTrendings] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [showCount, setShowCount] = useState(9);
  const maxShowCount = 4;
  const [isFilterFixed, setIsFilterFixed] = useState(false);
  const [variantSelected, setVariantSelected] = useState(0);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsFilterFixed(true);
    } else {
      setIsFilterFixed(false);
    }
  };

  useEffect(() => {
    axios
      .get(`/userProduct/userProduct?limit=${showCount}`)
      .then((res) => {
        const newArrivalProducts = res.data.filter(
          (product) => product.type === "Trending"
        );

        setTrendings(newArrivalProducts.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the scroll event listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showCount]);

  const handleAddtoCart = async (cartProduct) => {
    const selectedVariant = cartProduct.variant[variantSelected];
    var id = localStorage.getItem("id");
    if (!id) {
      toast("Please log in to add items to the cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
      return;
    }
    try {
      const cart = {
        productName: selectedVariant.productName,
        price: selectedVariant.price,
        imageUrl: selectedVariant.image[0],
      };

      const response = await axios.post(`/cart/cart/${id}`, cart);

      toast("Added To Cart !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        rtl: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        pauseOnFocusLoss: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [message, setMessage] = React.useState();

  const chooseMessage = (message) => {
    setMessage(message);
    if (message === 1) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "S") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 2) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "M") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 3) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "XS") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 4) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "L") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
    if (message === 5) {
      // sort alll xs Data
      let tempArr = [];
      userProducts.map((item) => {
        if (item.size === "XXL") {
          tempArr.push(item);
        }
      }, setUserProducts(tempArr));
    }
  };

  return (
    <>
      {" "}
      <ToastContainer />
      <div className="py-6 sm:py-8 lg:py-12 bg-[#E9ECEF]">
        <div className="">
          <h2 className="mb-4 text-center text-2xl text-gray-800 font-bold md:mb-8 lg:text-5xl">
            Trending Now
          </h2>
          <div class="mx-auto max-w-screen-2xl sm:flex ">
            <div className={`sticky top-0 ${isFilterFixed ? "h-full" : ""}`}>
              <Filter chooseMessage={chooseMessage} />
            </div>

            <div class=" py-6 sm:py-8 lg:py-0">
              <div class="mx-auto max-w-screen-xl px-4 md:px-6">
                <div class="grid gap-x-4 gap-y-10 grid-cols-2  sm:grid-cols-2 md:gap-x-6 lg:grid-cols-2 xl:grid-cols-5 2xl:grid-cols-5">
                  {/* {trendings.slice(0, showCount).map((trending, index) => (
                    <div key={trending._id} className="mb-6">
                      <button
                        onClick={() =>
                          navigate(`/productdetail/${trending._id}`, {
                            state: [trending],
                          })
                        }
                        class="group relative mb-2 block h-80  sm:h-[450px] overflow-hidden rounded bg-gray-100 shadow-sm  lg:mb-3"
                      >
                        <img
                          src={trending.variant[0].image[0]}
                          loading="lazy"
                          alt=""
                          class="h-full w-full border object-center transition duration-200 group-hover:scale-110"
                        />
                      </button>

                      <div className="flex gap-2 px-2">
                        <div className="flex flex-col">
                          <a
                            href="!#"
                            className="text-lg font-semibold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl"
                          >
                            {trending.variant[variantSelected].productName}
                          </a>
                          <span className="text-gray-500 py-1 text-lg font-semibold">
                            Rs. {trending.variant[variantSelected].price}
                          </span>
                        </div>
                      </div>
                      <div className="py-2">
                        <button
                          onClick={() => handleAddtoCart(trending)}
                          className="bg-gray-400 text-black font-semibold py-4 px-4 w-full"
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  ))} */}

                  {trendings.slice(0, showCount).map((trending, index) => (
                    <>
                      <div
                        key={index}
                        className="flex flex-col h-full justify-between "
                      >
                        <button
                          onClick={() =>
                            navigate(`/productdetail/${trending._id}`, {
                              state: [trending],
                            })
                          }
                          // class={`group relative mb-2 block h-80  sm:h-[480px] overflow-hidden rounded bg-gray-100 shadow-md  lg:mb-3 ${
                          //   selectedNewArrivals.includes(newArrival._id)
                          //     ? "border-4 border-primary"
                          //     : ""
                          // }`}
                        >
                          <img
                            src={trending.variant[0].image[0]}
                            loading="lazy"
                            alt=""
                            class="h-full w-full  object-center transition duration-200 group-hover:scale-110"
                          />
                          {trending.variant[variantSelected].price !==
                            trending.variant[variantSelected].mrp && (
                            <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">
                              Sale
                            </span>
                          )}
                        </button>

                        <div class="flex   px-1">
                          <div class="flex flex-col mt-4">
                            <a
                              href="!#"
                              class="text-xs  text-gray-800 transition duration-100 font-bold hover:text-gray-500 lg:text-base line-clamp-2"
                            >
                              {trending.variant[variantSelected].productName}
                            </a>
                            <div class="flex">
                              <span class="text-gray-800 py-1 text-sm font-semibold">
                                Rs {trending.variant[variantSelected].price}
                              </span>
                              {trending.variant[variantSelected].price !==
                                trending.variant[variantSelected].mrp && (
                                <span class="text-gray-800 flex py-1 px-2 text-sm font-semibold">
                                  <p className="px-2 font-bold line-through">
                                    ₹ {trending.variant[variantSelected].mrp}
                                  </p>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="py-2">
                          <button
                            onClick={() => handleAddtoCart(trending)}
                            className="bg-gray-300 rounded text-black font-semibold  py-3 px-4 w-full"
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center  justify-center my-8 w-full">
            <Link to="/trending">
              <button
                onClick={() => setShowCount(showCount + 9)}
                className=" shadow-md shadow-gray-300  tracking-wider font-assistant  lg:text-xl md:text-lg text-sm rounded f-m-m font-semibold text-white focus:outline-none lg:px-8 px-6 lg:py-6 py-3 xl:leading-4"
                style={{ background: "#AD5C5C" }}
              >
                Browse More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-6"></div>
    </>
  );
}

export default TrendingCollection;
