import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <h1 className="loader-text text-center">
        Ariz Garments
        <br />
        <span className="text-lg">(A Unit of El Ombre Exports Pvt Ltd.)</span>
      </h1>

      <style jsx>{`
        .loader-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #f8f8f8; /* Set your background color */
        }
        .loader-text {
          font-size: 3rem;
          color: #333; /* Set your text color */
          animation: fadeIn 3s ease-in-out, bounce 1s infinite;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-30px);
          }
          60% {
            transform: translateY(-15px);
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
