import React from "react";

function Socials() {
  return (
    <div>
      <div className=" md:py-0  py-0 ">
        <div id="insta-feed" class="text-center">
          <h2 class="lg:text-4xl font-heading text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-800 md:w-full w-9/12 mx-auto">
            Follow Us on Instagram
          </h2>
          <p class="font-normal text-base px-10 leading-6 text-gray-600 mt-6 lg:w-5/12 md:w-9/12 mx-auto">
            Follow us on Instagram
            <a
              href="https://www.instagram.com/ariz.garments/?igshid=MzRlODBiNWFlZA%3D%3D"
              class="underline underline-offset-4 cursor-pointer p-2 hover:text-red-800 hover:font-semibold"
            >
              @ariz.garments
            </a>
            and tag us to get featured on our timeline
          </p>
          <div id="instagram-posts" class="mt-6"></div>
        </div>
        <div
          className=" grid lg:grid-cols-6 sm:grid-cols-2 grid-cols-3  mt-10"
          id="insta-feed"
        >
          <a
            href="https://www.instagram.com/p/C2MOimvvtN9/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/9opi9s0u0lvmr23msucmk/soft-denim-crop-toppp.jpg?rlkey=uudk2yb5idba69s4kpvocihfc&st=pyj2ogkt&dl=0"
              alt=""
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/9opi9s0u0lvmr23msucmk/soft-denim-crop-toppp.jpg?rlkey=uudk2yb5idba69s4kpvocihfc&st=pyj2ogkt&dl=0"
              alt=""
              className="lg:hidden block w-full "
            />
            <div className=" flex justify-center items-center opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/p/CkuucfbPw_l/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/7tctonnvjtf36yfwyq2ao/advertisment.jpg?rlkey=puktfbijvnj3impc4wxl9bk1p&st=oe77r5cg&dl=0"
              alt="Smiling Girl"
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/7tctonnvjtf36yfwyq2ao/advertisment.jpg?rlkey=puktfbijvnj3impc4wxl9bk1p&st=oe77r5cg&dl=0"
              alt="Smiling Girl"
              className="lg:hidden block w-full "
            />
            <div className="opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/p/C1YtZlBv6nh/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/qfcurkbxa6xd6i87ml4oi/flared-top-tie-up-strips.jpg?rlkey=gk4jlbaxy48hmfbq7rsde6eb0&st=anb2c88t&dl=0"
              alt="Men Posing"
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/qfcurkbxa6xd6i87ml4oi/flared-top-tie-up-strips.jpg?rlkey=gk4jlbaxy48hmfbq7rsde6eb0&st=anb2c88t&dl=0"
              alt="Men Posing"
              className="lg:hidden block w-full "
            />
            <div className="opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/p/C0WTeJjvyai/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/zhdad01kvgzrjh2skuo47/one-shoulder-satin-dress.jpg?rlkey=kkhwk546ozwxbrk6xxmhzhy4h&st=2zjkkmhb&dl=0"
              alt="2 puppies"
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/zhdad01kvgzrjh2skuo47/one-shoulder-satin-dress.jpg?rlkey=kkhwk546ozwxbrk6xxmhzhy4h&st=2zjkkmhb&dl=0"
              alt="2 puppies"
              className="lg:hidden block w-full "
            />
            <div className="opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/p/C1bSspQvVHo/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/y34826y1f0wr2gezfhi1u/puff-sleeves-crop-top.jpg?rlkey=jm81p5nem3fr357442rnzbb0b&st=jqjmw685&dl=0"
              alt=""
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/y34826y1f0wr2gezfhi1u/puff-sleeves-crop-top.jpg?rlkey=jm81p5nem3fr357442rnzbb0b&st=jqjmw685&dl=0"
              alt=""
              className="lg:hidden block w-full "
            />
            <div className=" flex justify-center items-center opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/p/C0TiFZUPXak/"
            className="relative group"
          >
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/s49a55nrzpvhvds059mwb/Sleeveless-dress-with-round-neck-crop-top2.jpg?rlkey=f23a54og1h45m33oiw7n7jo69&st=gs6w2fzn&dl=0"
              alt="Smiling Girl"
              className=" lg:block hidden w-full "
            />
            <img
              src="https://dl.dropboxusercontent.com/scl/fi/s49a55nrzpvhvds059mwb/Sleeveless-dress-with-round-neck-crop-top2.jpg?rlkey=f23a54og1h45m33oiw7n7jo69&st=gs6w2fzn&dl=0"
              alt="Smiling Girl"
              className="lg:hidden block w-full "
            />
            <div className="opacity-0 bg-gradient-to-t from-gray-800 via-gray-800 to-opacity-30 group-hover:opacity-50 absolute top-0 left-0 h-full w-full" />
            <div className=" absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100">
              <svg
                width={64}
                height={64}
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6665 10.6665H21.3332C15.4421 10.6665 10.6665 15.4421 10.6665 21.3332V42.6665C10.6665 48.5575 15.4421 53.3332 21.3332 53.3332H42.6665C48.5575 53.3332 53.3332 48.5575 53.3332 42.6665V21.3332C53.3332 15.4421 48.5575 10.6665 42.6665 10.6665Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40Z"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M44 20V20.001"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Socials;
