import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Trending() {
  const navigate = useNavigate();
  const [trendings, setTrendings] = useState([]);
  const [showCount, setShowCount] = useState(8);
  const maxShowCount = 4;
  const [variantSelected, setVariantSelected] = useState(0);

  useEffect(() => {
    axios
      .get(`/userProduct/userProduct?limit=${showCount}`)
      .then((res) => {
        const trendingProducts = res.data.filter(
          (product) => product.type === "Trending"
        );

        setTrendings(trendingProducts.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showCount]);

  const handleAddtoCart = async (cartProduct) => {
    const selectedVariant = cartProduct.variant[variantSelected];
    var id = localStorage.getItem("id");
    if (!id) {
      toast("Please log in to add items to the cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
      return;
    }
    try {
      const cart = {
        productName: selectedVariant.productName,
        price: selectedVariant.price,
        imageUrl: selectedVariant.image[0],
      };

      const response = await axios.post(`/cart/cart/${id}`, cart);

      toast("Added To Cart !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        rtl: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        pauseOnFocusLoss: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="py-6 sm:py-8 lg:py-12 bg-[#E9ECEF]">
      <div class="mx-auto max-w-screen-full px-4 md:px-40">
        <div className="mb-8">
          <h2 className="mb-4 text-center font-bold text-2xl text-gray-800 md:mb-6 lg:text-5xl">
            Trending Now
          </h2>
        </div>

        <div class=" py-6 sm:py-8 lg:py-12">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class="grid gap-x-4 gap-y-8 grid-cols-2  sm:grid-cols-2 md:gap-x-6 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {trendings.slice(0, showCount).map((trending, index) => (
                <div
                  key={trending._id}
                  className="flex flex-col h-full justify-between"
                >
                  <button
                    onClick={() =>
                      navigate(`/productdetail/${trending._id}`, {
                        state: [trending],
                      })
                    }
                    class="group relative mb-2 block overflow-hidden rounded bg-gray-100 shadow-sm  lg:mb-3"
                  >
                    <img
                      src={trending.variant[0].image[0]}
                      loading="lazy"
                      alt=""
                      className="h-full w-full  object-center transition duration-200 group-hover:scale-110"
                    />
                    {trending.variant[variantSelected].price !==
                      trending.variant[variantSelected].mrp && (
                      <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">
                        Sale
                      </span>
                    )}
                  </button>

                  <div className="flex mt-2 gap-2">
                    <div className="flex flex-col">
                      <a
                        href="!#"
                        className="text-xs  text-gray-800 transition duration-100 hover:text-gray-500 lg:text-lg font-bold line-clamp-2"
                      >
                        {trending.variant[variantSelected].productName}
                      </a>

                      <div class="flex">
                        <span class="text-gray-800 py-1 text-sm font-semibold">
                          Rs {trending.variant[variantSelected].price}
                        </span>
                        {trending.variant[variantSelected].price !==
                          trending.variant[variantSelected].mrp && (
                          <span class="text-gray-800 flex py-1 px-2 text-sm font-semibold">
                            <p className="px-2 font-bold line-through">
                              ₹ {trending.variant[variantSelected].mrp}
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <button
                      onClick={() => handleAddtoCart(trending)}
                      className="bg-stone-300 text-black font-semibold py-3 px-3 w-full"
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="justify-center text-center">
          <Link
            to="/trending"
            class="inline-block rounded-lg border justify-center text-center bg-white px-4 py-2  text-sm font-semibold text-gray-700 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
          >
            More Collections
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Trending;
