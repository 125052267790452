import React from "react";
import { Link } from "react-router-dom";

function CollectionBanner() {
  return (
    <div>
      <div class="bg-white py-12 sm:py-8 lg:py-24">
        <div class="mx-auto max-w-screen-full  px-2 md:px-8">
          <div class="grid  sm:grid-cols-2">
            <Link
              to="/trending"
              href="!#"
              class="group relative flex  items-end overflow-hidden rounded  p-2 "
            >
              <img
                src="/Trending now 3 Final.jpg"
                loading="lazy"
                alt=""
                class=" inset-0 h-full w-full object-cover object-center rounded-2xl"
              />
            </Link>

            <Link
              to="/newarrival"
              class="group relative flex items-end overflow-hidden rounded  p-2 "
            >
              <img
                src="/New Arrival 3 Final.jpg"
                loading="lazy"
                alt=""
                class=" inset-0 h-full w-full object-cover object-center rounded-2xl"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionBanner;
