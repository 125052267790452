import React, { useState, useEffect } from "react";
import axios from "../../api/axios";

function Review() {
  const [customerName, setCustomerName] = useState("");
  const [date, setDate] = useState("");
  // const [time, setTime] = useState("");
  const [remarks, setRemarks] = useState("");
  const [reviews, setReviews] = useState([]);
  const [showCount, setShowCount] = useState(4);
  const [selectedStarRating, setSelectedStarRating] = useState(0);

  const handleStarClick = (rating) => {
    // Update the selectedStarRating state when a star is clicked
    setSelectedStarRating(rating);
  };

  useEffect(() => {
    axios

      .get(`/review/review?limit=${showCount}`)
      .then((res) => {
        setReviews(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(`/review/review?limit=${showCount}`)
      .then((res) => {
        const reversedReviews = res.data.reverse();
        setReviews(reversedReviews);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/review/review", {
        customerName,
        date,
        rating: selectedStarRating,
        remarks,
      });
      console.log("Added");
      alert("Review added successfully!");
      setCustomerName("");
      setDate("");
      setRemarks("");
      window.location.reload(); // Automatic reload after successful form submission
    } catch (err) {
      console.error(err);
      alert("Error adding review");
    }
  };

  const averageRating =
    reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length ||
    0;

  const starDistribution = [0, 0, 0, 0, 0];

  reviews.forEach((review) => {
    starDistribution[review.rating - 1]++;
  });
  return (
    <div>
      <div class=" py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 lg:gap-12">
            <div>
              <div class="rounded-lg border p-4">
                <h2 class="mb-3 text-lg font-bold text-gray-800 lg:text-xl">
                  Customer Reviews
                </h2>

                <div class="mb-0.5 flex items-center gap-2">
                  <div class="-ml-1 flex gap-0.5">
                    {[...Array(5)].map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        class={`h-6 w-6 ${
                          index < averageRating
                            ? "text-yellow-400"
                            : "text-gray-300"
                        }`}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>

                  <span class="text-sm font-semibold">{`${averageRating}/5`}</span>
                </div>

                <span class="block text-sm text-gray-500">
                  Based on {reviews.length} reviews
                </span>

                {/* ... (Existing code for individual star ratings) */}

                <div class="my-5 flex flex-col gap-2 border-t border-b py-5">
                  {[5, 4, 3, 2, 1].map((starRating) => (
                    <div class="flex items-center gap-3" key={starRating}>
                      <span class="w-10 whitespace-nowrap text-right text-sm text-gray-600">
                        {starRating} Star
                      </span>

                      <div class="flex h-4 flex-1 overflow-hidden rounded bg-gradient-to-r from-gray-200 to-yellow-400">
                        <span
                          class={`h-full w-${
                            (starDistribution[starRating - 1] /
                              reviews.length) *
                            100
                          } rounded bg-yellow-400`}
                        ></span>
                      </div>

                      <span class="text-xs text-gray-500">
                        {starDistribution[starRating - 1]}
                      </span>
                    </div>
                  ))}
                </div>

                <div class="block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base">
                  Write a review Below
                </div>
                <form
                  className="flex flex-wrap py-10 flex-row -mx-4"
                  onSubmit={handleSubmit}
                >
                  <div class="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                    <label
                      for="inputfirst4"
                      class="inline-block mb-2 font-semibold text-base"
                    >
                      User Name :
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      class="w-full leading-5 relative py-3 px-5 text-black font-semibold tracking-wider bg-white rounded-md  border border-gray-300 overflow-x-auto focus:outline-none "
                      id="inputfirst4"
                      required
                    />
                  </div>
                  <div class="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                    <label
                      for="inputlast4"
                      class="inline-block mb-2 font-semibold text-base"
                    >
                      Date :
                    </label>
                    <input
                      type="date"
                      name="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      class="w-full leading-5 relative py-3 px-5 text-black   bg-white rounded-md  border border-gray-300 overflow-x-auto focus:outline-none "
                      id="inputlast4"
                      required
                    />
                  </div>

                  <div class="flex-shrink max-w-full px-4 w-full  mb-6">
                    <label
                      for="inputfirst4"
                      class="inline-block mb-2 font-semibold text-base"
                    >
                      Ratings
                    </label>
                    <div class="-ml-1 flex gap-0.5">
                      {[...Array(5)].map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 cursor-pointer ${
                            index < selectedStarRating
                              ? "text-yellow-400"
                              : "text-gray-300"
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          onClick={() => handleStarClick(index + 1)}
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                      <span class="px-4 text-sm font-semibold">{`${selectedStarRating}/5`}</span>
                    </div>
                  </div>

                  <div class="flex-shrink max-w-full px-4 w-full  mb-6">
                    <label
                      for="inputfirst4"
                      class="inline-block mb-2 font-semibold text-base"
                    >
                      Remarks :
                    </label>
                    <textarea
                      type="text"
                      name="username"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      class="w-full leading-5 relative py-3 px-5 text-black font-semibold tracking-wider bg-white rounded-md  border border-gray-300 overflow-x-auto focus:outline-none "
                      id="inputfirst4"
                      required
                    />
                  </div>
                  <div class="flex-shrink max-w-full px-4 w-full">
                    <button
                      onClick={handleSubmit}
                      class="flex items-center py-3 px-5 leading-5 text-white font-semibold rounded-md bg-orange-600 hover:text-white hover:bg-gray-900 hover:ring-0 focus:outline-none focus:ring-0"
                      type="submit"
                    >
                      Add Review
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div class="lg:col-span-2">
              <div class="border-b pb-4 md:pb-6">
                <h2 class="text-lg font-bold text-gray-800 lg:text-xl">
                  Top Reviews
                </h2>
              </div>

              <div class="divide-y">
                {reviews.slice(0, showCount).map((review, index) => (
                  <div class="flex flex-col gap-3 py-4 md:py-8">
                    <div>
                      <span class="block text-sm font-bold">
                        {review.customerName}
                      </span>
                      <span class="block text-sm text-gray-500">
                        {review.date}
                      </span>
                    </div>

                    <div className="-ml-1 flex gap-0.5">
                      {[...Array(5)].map((_, starIndex) => (
                        <svg
                          key={starIndex}
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-5 w-5 ${
                            starIndex < review.rating
                              ? "text-yellow-400"
                              : "text-gray-300"
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>

                    <p class="text-gray-600">{review.remarks}</p>
                  </div>
                ))}
              </div>

              <div class="border-t pt-6">
                <div
                  onClick={() => setShowCount(showCount + 4)}
                  class="flex items-center gap-0.5 font-semibold cursor-pointer text-indigo-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                >
                  Read all reviews
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
