import React from "react";
import Profile from "../../components/Profile/Profile";

function ProfilePage() {
  return (
    <div>
      <Profile />
    </div>
  );
}

export default ProfilePage;
