import React from "react";
import BlogPost from "../../components/BlogPost/BlogPost";

function BlogPostPage() {
  return (
    <div>
      <BlogPost />
    </div>
  );
}

export default BlogPostPage;
