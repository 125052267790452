import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Filter from "../Filter/Filter";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NewArrivalCollection() {
  const [userProducts, setUserProducts] = useState([]); // For displaying filtered products
  const [initialProducts, setInitialProducts] = useState([]); // For storing the initial unfiltered products
  const [showCount, setShowCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10;
  const { category, subcategory, subcategory1 } = useParams();
  const [carts, setCarts] = useState([]);
  const [isFilterFixed, setIsFilterFixed] = useState(false);
  const [variantSelected, setVariantSelected] = useState(0);
  const [message, setMessage] = useState(null); // To track filter state
  const [minPrice, setMinPrice] = useState(0); // Minimum price filter value
  const [maxPrice, setMaxPrice] = useState(50000);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsFilterFixed(true);
    } else {
      setIsFilterFixed(false);
    }
  };

  useEffect(() => {
    axios
      .get(`/userProduct/userProduct`)
      .then((res) => {
        const reversedUserProducts = res.data.reverse();
        let filteredProducts = reversedUserProducts;

        if (category) {
          filteredProducts = filteredProducts.filter(
            (item) => item.category === category
          );
        }

        if (subcategory) {
          filteredProducts = filteredProducts.filter(
            (item) => item.subcategory === subcategory
          );
        }

        if (subcategory1) {
          filteredProducts = filteredProducts.filter(
            (item) => item.subcategory1 === subcategory1
          );
        }

        setUserProducts(filteredProducts);
        setInitialProducts(filteredProducts);
        setShowCount(filteredProducts.length);
        setCurrentPage(0);
      })
      .catch((err) => {
        console.log(err);
      });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [category, subcategory, subcategory1]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const handleAddtoCart = async (cartProduct) => {
    const selectedVariant = cartProduct.variant[variantSelected];
    const cart = {
      productName: selectedVariant.productName,
      price: selectedVariant.price,
      imageUrl: selectedVariant.image[0],
    };
    const id = localStorage.getItem("id");
    if (!id) {
      toast("Please log in to add items to the cart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        theme: "dark",
      });
      return;
    }
    await axios
      .post(`/cart/cart/${id}`, cart)
      .then((res) => {
        setCarts(res.data);
        toast("Added To Cart !", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          rtl: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          pauseOnFocusLoss: true,
          progress: undefined,
          transition: Slide,
          theme: "dark",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterByPrice = (min, max) => {
    const filteredByPrice = initialProducts.filter((product) => {
      return product.variant.some((variant) => {
        const isAboveMin = variant.price >= min; // Check if above min
        const isBelowMax = variant.price <= max; // Check if below max

        return isAboveMin && isBelowMax;
      });
    });

    setUserProducts(filteredByPrice);
    setShowCount(filteredByPrice.length);

    if (filteredByPrice.length === 0) {
    } else {
      setUserProducts(filteredByPrice);
    }
  };

  const chooseMessage = (selectedSizes) => {
    if (!Array.isArray(selectedSizes)) {
      selectedSizes = [selectedSizes];
    }

    setMessage(selectedSizes);

    if (selectedSizes.length === 0) {
      setUserProducts(initialProducts);
      return;
    }

    const filteredProducts = initialProducts.filter((item) =>
      selectedSizes.includes(item.size)
    );

    setUserProducts(filteredProducts);

    if (filteredProducts.length === 0) {
      toast("No products available for the selected sizes", {
        position: "top-center",
        autoClose: 2000,
        theme: "dark",
      });
    }
  };

  const handleResetFilters = () => {
    setMessage([]);
    setMinPrice(0); // Optionally reset price filters
    setMaxPrice(50000); // Reset price to the default value
    setUserProducts(initialProducts);
    setFilterKey((prevKey) => prevKey + 1);
  };

  const handleSizeChange = (sizeValue) => {
    let updatedSizes = Array.isArray(message) ? [...message] : [];

    if (updatedSizes.includes(sizeValue)) {
      updatedSizes = updatedSizes.filter((size) => size !== sizeValue);
    } else {
      updatedSizes.push(sizeValue);
    }

    // Pass the updated list of selected sizes to chooseMessage
    chooseMessage(updatedSizes);
  };

  const [filterKey, setFilterKey] = useState(0);

  return (
    <div>
      <div class=" py-6 sm:py-8  lg:py-16" style={{ background: "#FEFCFB" }}>
        <div className="max-w-screen-2xl mx-auto sm:flex ">
          <div
            className={` sticky -mt-6 top-0 md:top-0 z-20 ${
              isFilterFixed ? "h-full" : ""
            }`}
          >
            <Filter
              key={filterKey}
              chooseMessage={chooseMessage}
              handleSizeChange={handleSizeChange}
              choosePriceRange={(min, max) => {
                setMinPrice(min);
                setMaxPrice(max);
                filterByPrice(min, max);
              }}
              selectedSizes={message}
              handleResetFilters={handleResetFilters}
            />
          </div>
          <div class="mt-6 sm:mt-0">
            <div className=" px-6 md:px-8 flex gap-6 justify-between">
              <h2 class="  text-sm text-center text-gray-700 font-bold lg:text-lg">
                {category
                  ? `${category} ${subcategory ? ` > ${subcategory}` : ""} ${
                      subcategory1 ? ` > ${subcategory1}` : ""
                    }`
                  : "All Products"}
              </h2>
              <h2 class="text-sm block md:-mt-1.5 text-gray-700 font-bold md:text-lg">
                Showing result {currentPage * perPage + 1} -{" "}
                {Math.min((currentPage + 1) * perPage, showCount)} of{" "}
                {showCount}
              </h2>
            </div>

            <div class=" py-2 sm:py-8 lg:py-4">
              <div class="max-w-screen-xl mx-auto px-4 md:px-6">
                <div className="flex flex-wrap w-full">
                  {userProducts
                    .slice(currentPage * perPage, (currentPage + 1) * perPage)
                    .map((userProduct, index) =>
                      userProduct.variant.map((variant, vIndex) => (
                        <div
                          key={index}
                          className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 mb-2 p-2 "
                        >
                          <div key={vIndex} className="mb-4 ">
                            <button
                              onClick={() =>
                                navigate(`/productdetail/${userProduct._id}`, {
                                  state: [userProduct],
                                })
                              }
                              className="group relative block w-full aspect-w-4 aspect-h-5 overflow-hidden rounded shadow-md"
                            >
                              <img
                                src={variant?.image[0]}
                                loading="lazy"
                                alt=""
                                className="h-full w-full object-center transition duration-200 group-hover:scale-110"
                              />
                              {userProduct?.variant[variantSelected].price !==
                                userProduct?.variant[variantSelected].mrp && (
                                <span className="absolute left-0 top-0 rounded-br-lg bg-red-500 px-3 py-1.5 text-sm uppercase tracking-wider text-white">
                                  Sale
                                </span>
                              )}
                            </button>

                            <div class="flex flex-col justify-between h-32">
                              <div class="mt-4">
                                <a
                                  href="!#"
                                  class="text-xs text-gray-800 transition duration-100  font-bold hover:text-gray-500 lg:text-base line-clamp-1"
                                >
                                  {variant.productName}
                                </a>
                              </div>
                              <div class="flex">
                                <span class="text-gray-800 py-1 text-sm font-semibold">
                                  Rs{" "}
                                  {userProduct.variant[variantSelected].price}
                                </span>
                                {userProduct.variant[variantSelected].price !==
                                  userProduct.variant[variantSelected].mrp && (
                                  <span class="text-gray-800 flex py-1 px-2 text-sm font-semibold">
                                    <p className="px-2 font-bold line-through">
                                      ₹{" "}
                                      {userProduct.variant[variantSelected].mrp}
                                    </p>
                                  </span>
                                )}
                              </div>

                              <div className="py-6">
                                <button
                                  onClick={() => handleAddtoCart(userProduct)}
                                  className="bg-gray-200 rounded text-black font-semibold py-3 px-4 w-full"
                                >
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center my-4 w-full pagination-container">
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(userProducts.length / perPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-8">
        <p className="text-xl text-black">{chooseMessage ?? "N/A"}</p>
      </div>
    </div>
  );
}

export default NewArrivalCollection;
